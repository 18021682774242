import React, { useState, useEffect, useRef } from 'react';
import {useSubheader} from "../../_metronic/layout";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { Formik, Field, Form, ErrorMessage, FieldArray, useField, useFormikContext } from 'formik';
import LaunchIcon from '@material-ui/icons/Launch';
import { useAuth0 } from "@auth0/auth0-react";

export const Search = (props) => {
  const [initialValues, setInitialValues] = useState({query: ''});
  const suhbeader = useSubheader();
  const [resultsOrders, setResultsOrders] = useState();
  const [resultsInventory, setResultsInventory] = useState();
  const [resultsInvoices, setResultsInvoices] = useState();
  const [statusMsg, setStatusMsg] = useState('Please start search...');
  const [numFound, setNumFound] = useState(0);
  const [count, setCount] = useState(0);
  const paramsQuery = props.match.params.query
  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  suhbeader.setTitle("My Custom title");

  async function handleSearch(searchQuery) {
    let token = await getAccessTokenSilently();

    setStatusMsg('Searching...')
    setNumFound(0)

    return fetch("https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/search", {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + token
      },
      body: JSON.stringify({query: searchQuery})
    }).then(async (response) => {
      props.history.push('/search/' + searchQuery)
      const data = await response.json()
      console.log(data)

      let orders = []
      let inventory = []
      let invoices = []

      data.results.forEach(function (item, index) {
        switch(item.type[0]) {
          case "order":
            orders.push(item)
            break
          case "inventory":
            inventory.push(item)
            break
          case "invoice":
            invoices.push(item)
            break
          default:
            console.log("Unknown type: " + item.type)
            console.log(item)
        }
      });

      setResultsOrders(orders)
      setResultsInventory(inventory)
      setResultsInvoices(invoices)
      setNumFound(data.numFound)
      setCount(data.count)

      if(data.numFound === 0) {
        setStatusMsg('No results found.')
      }
    })
  }

  function buildItemTable(row) {
    console.log(row)
    return (
      <TableRow>
        <TableCell>{(row.type[0] == "order") ? (row.customerReference[0]) : ("-")}</TableCell>
        <TableCell align="right">{row.comments[0]}</TableCell>
        <TableCell align="right"><Link component={RouterLink} to={`/orders/${row.id}/`}><LaunchIcon /></Link></TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    if (paramsQuery) {
      handleSearch(paramsQuery).then(() => {
        setInitialValues({query: paramsQuery})
      });
    }
  }, []);

  return (
    <>
      <h1>Search</h1>
      <div className="row">
        <div className="col">
          <div className="row">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={async (values, actions) => {
                handleSearch(values.query).then(() => {
                  actions.setSubmitting(false)
                })
              }}
            >
              {({ setFieldValue, handleSubmit, values }) => (
                <Form>
                  <div className="col">
                    <div className="row m-2">
                      <Field name="query">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => (
                          <TextField
                            fullWidth
                            id={field.name}
                            label="Search Query"
                            error={meta.touched && Boolean(meta.error)}
                            helperText={meta.touched && meta.error}
                            {...field}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="row m-2">
                    </div>
                    <div className="row m-2">
                      <Button color="secondary" variant="contained" fullWidth type="button"
                        onClick={() => {
                          //setFieldValue('submitType', 'save', false)
                          setTimeout(handleSubmit(),0)
                        }}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          { (numFound > 0) ? ( 
            <div>
              <div className="row ml-0 m-10">
                <h4>Showing {count} of {numFound} results.</h4>
              </div>
              <div className="row m-2">
              </div>
              <div className="row m-2">
                <h4>Orders ({resultsOrders.length})</h4>
              </div>
              <div className="row m-2">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Customer Reference</TableCell>
                        <TableCell align="right">Part Number</TableCell>
                        <TableCell align="right">Alt Part Number</TableCell>
                        <TableCell align="right">Comments</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resultsOrders.map((row) => (
                        <TableRow key={row.id} >
                          <TableCell>{row.customerReference[0]}</TableCell>
                          <TableCell align="right">{(row['items_0.partNumber'] && row['items_0.partNumber'].length > 0) ? (row['items_0.partNumber'][0]) : ("-")}</TableCell>
                          <TableCell align="right">{(row['items_0.altPartNumber'] && row['items_0.altPartNumber'].length > 0) ? (row['items_0.altPartNumber'][0]) : ("-")}</TableCell>
                          <TableCell align="right">{row.comments[0]}</TableCell>
                          <TableCell align="right"><Link component={RouterLink} to={`/orders/${row.id}/`}><LaunchIcon /></Link></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="row m-2">
              <h4>Inventory ({resultsInventory.length})</h4>
              </div>
              <div className="row m-2">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Part Number</TableCell>
                        <TableCell align="right">Alt. Part Number</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Cost</TableCell>
                        <TableCell align="right">Cost Currency</TableCell>
                        <TableCell align="right">Location</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resultsInventory.map((row) => (
                        <TableRow key={row.id} >
                            <TableCell>{(row.partNumber && row.partNumber.length > 0) ? (row.partNumber[0]) : ("-")}</TableCell>
                            <TableCell align="right">{(row.altPartNumber && row.altPartNumber.length > 0) ? (row.altPartNumber[0]) : ("-")}</TableCell>
                            <TableCell align="right">{row.quantity}</TableCell>
                            <TableCell align="right">{row.cost}</TableCell>
                            <TableCell align="right">{(row.costCurrency && row.costCurrency.length > 0) ? (row.costCurrency[0]) : ("-")}</TableCell>
                            <TableCell align="right">{(row.location && row.location.length > 0) ? (row.location[0]) : ("-")}</TableCell>
                            <TableCell align="right"><Link component={RouterLink} to={`/inventory/${row.id}/`}><LaunchIcon /></Link></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          ):(
            <div className="row m-10">
              <h4>{statusMsg}</h4>
            </div>
          )}
        </div>
      </div>
    </>
  )
};
