/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useState, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useAuth0 } from "@auth0/auth0-react";
import { LayoutSplashScreen } from "../_metronic/layout";


export function Routes() {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    useEffect(() => {
      (async function login() {
        if (!isLoading && !user) {
          await loginWithRedirect();
        }
      })();
    }, [isLoading]);

    return (
        <Switch>
            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>

            {isLoading || !isAuthenticated ? (
                <LayoutSplashScreen />
            ) : (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
    );
}
