import React, {Suspense} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";
import {InvoiceForm} from "./pages/Invoices";
import {OrderForm} from "./pages/Orders";
import {InventoryForm} from "./pages/Inventory";
import {Search} from "./pages/Search";

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute exact path="/invoices" component={InvoiceForm}/>
                <ContentRoute path="/invoices/:id" component={InvoiceForm}/>
                <ContentRoute exact path="/orders" component={OrderForm}/>
                <ContentRoute path="/orders/:id" component={OrderForm}/>
                <ContentRoute exact path="/inventory" component={InventoryForm}/>
                <ContentRoute path="/inventory/:id" component={InventoryForm}/>
                <ContentRoute exact path="/search" component={Search}/>
                <ContentRoute path="/search/:query" component={Search}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
