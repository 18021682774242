export async function getPresignedUploadParams(name, size, lastModifiedDate, token) {
  console.log("getPresignedUploadParams called")
  // {
  //   "fields": {
  //     "AWSAccessKeyId": "AKIAJSQUO7ORWYVCSV6Q",
  //     "acl": "public-read",
  //     "key": "files/89789486-d94a-4251-a42d-18af752ab7d2-test.txt",
  //     "policy": "eyJleHBpcmF0aW9uIjogIjIwMTgtMTAtMzBUMjM6MTk6NDdaIiwgImNvbmRpdGlvbnMiOiBbeyJhY2wiOiAicHVibGljLXJlYWQifSwgWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsIDEwLCAzMTQ1NzI4MF0sIHsiYnVja2V0IjogImJlYW10ZWNoLWZpbGUifSwgeyJrZXkiOiAiY29tcGFueS8zLzg5Nzg5NDg2LWQ5NGEtNDI1MS1hNDJkLTE4YWY3NTJhYjdkMi10ZXN0LnR4dCJ9XX0=",
  //     "signature": "L7r3KBtyOXjUKy31g42JTYb1sio="
  //   },
  //   "fileUrl": "https://my-bucket.s3.amazonaws.com/files/89789486-d94a-4251-a42d-18af752ab7d2-test.txt",
  //   "uploadUrl": "https://my-bucket.s3.amazonaws.com/"
  // }

  let response
  let response_json

  response = await fetch('https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/files', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token
    },
    body: JSON.stringify({'name': name, 'size': size, 'lastModifiedDate': lastModifiedDate})
  })
  response_json = await response.json()
  return response_json
}