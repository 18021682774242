import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuth0 } from "@auth0/auth0-react";


let defaultValues = {
  contact: '',
  invoiceNumber: '',
  date: new Date().toLocaleDateString("en-AU"),
  orderNumber: '',
  billingAttn: '',
  billingCompany: '',
  billingAddress1: '',
  billingAddress2: '',
  billingCity: '',
  billingState: '',
  billingPostCode: '',
  deliveryAttn: '',
  deliveryCompany: '',
  deliveryAddress1: '',
  deliveryAddress2: '',
  deliveryCity: '',
  deliveryState: '',
  deliveryPostCode: '',
  items: [
    {
      partNumber: '',
      partNumberAlt: '',
      description: '',
      traceability: '',
      ordered: '',
      shipped: '',
      unitPrice: '',
      cofc: false
    },
  ],
  gstFree: false,
  submitType: 'save',
  deliveryTrackingNumber: '',
  deliveryProvider: ''
};

const validationSchema = Yup.object().shape({
  lastName: Yup.string('')
    .email('Enter a valid email')
    .required('Email is required'),
  // friends: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       name: Yup.string().required("Name is required"),
  //       email: Yup.string()
  //         .email("Invalid email")
  //         .required("Please enter email"),
  //     })
  //   )
  //   .min(1, "Need at least a friend")
});

// Check all items to see if any supporting documenation is required.
function checkSupportDocsRequired(items) {
  for (let item of items) {
    if (item['cofc'] === true) {
      return true
    }
  }
  return false
}

// Custom field type to enable pre-fill of contact data
const MyField = (props) => {
  const {
    values: { contact },
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField(props);
  const [contactValues, setContactValues] = useState([]);

  const { getAccessTokenSilently } = useAuth0();

// Retrieve selected contact data
  async function fetchContact(id) {
    let token = await getAccessTokenSilently();

    return fetch("https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/contacts/" + id, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + token
      }
    }).then(async (response) => {
      const data = await response.json()
      console.log(data)
      return data
    })
  }

  async function getContactValues() {
    let token = await getAccessTokenSilently();

    return fetch("https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/contacts/", {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + token
      }
    }).then(async (response) => {
      const data = await response.json()
      console.log(data['id_map'])
      return data['id_map']
    })
  }

  useEffect(() => {
    let isCurrent = true;

    // Initialise at form load or re-initialise if we have a new invoice to update the UI.
    if (contactValues.length == 0 || !contactValues.includes(contact)) {
      getContactValues().then(res => setContactValues(res));
    }

    if (contact != null && contact != '') {
      // your business logic around when to fetch goes here.
      fetchContact(contact).then((response) => {
        if (!!isCurrent) {
          // prevent setting old values
          setFieldValue('billingAttn', response['billingAttn']);
          setFieldValue('billingCompany', response['billingCompany']);
          setFieldValue('billingAddress1', response['billingAddress1']);
          setFieldValue('billingAddress2', response['billingAddress2']);
          setFieldValue('billingCity', response['billingCity']);
          setFieldValue('billingState', response['billingState']);
          setFieldValue('billingPostCode', response['billingPostCode']);
          setFieldValue('deliveryAttn', response['deliveryAttn']);
          setFieldValue('deliveryCompany', response['deliveryCompany']);
          setFieldValue('deliveryAddress1', response['deliveryAddress1']);
          setFieldValue('deliveryAddress2', response['deliveryAddress2']);
          setFieldValue('deliveryCity', response['deliveryCity']);
          setFieldValue('deliveryState', response['deliveryState']);
          setFieldValue('deliveryPostCode', response['deliveryPostCode']);
        }
      });
    }

    return () => {
      isCurrent = false;
    };
  }, [contact, setFieldValue, props.name]);

  return (
    <>
      <div className="col">
        <TextField
          id="contacts"
          select
          label="Contact"
          style={{width:"100%"}}
          {...field}
        >
          {contactValues.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="col text-center my-auto">
        <Button color="secondary"
          variant="contained" 
          onClick={() => getContactValues().then(res => setContactValues(res))}
        >
          Refresh
        </Button>
      </div>
    </>
  );
};

export const InvoiceForm = (props) => {
  const [initialValues, setInitialValues] = useState();
  const paramsId = props.match.params.id
  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  async function getInitialValues(id) {
    let token = await getAccessTokenSilently();

    return fetch("https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/invoices/" + id, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
       'Accept': 'application/json',
       'Authorization': "Bearer " + token
      }
    }).then(async (response) => {
      const data = await response.json()
      console.log(data)
      return data
    })
  }

  useEffect(() => {
    if (paramsId) {
      getInitialValues(paramsId).then((res) => {
        res.contact = ''; // Ensure invoice is displayed as is as contacts can change throughout their lifetime.
        setInitialValues(res)
      });
    } else {
      setInitialValues(defaultValues)
    }
  }, []);

  return initialValues ? (

  <div>
  <h1>Invoice Generator</h1>
  <Formik
    initialValues={initialValues}
    // enableReinitialize={true}
    onSubmit={async (values, actions) => {
      //await new Promise((r) => setTimeout(r, 500));
      //alert(JSON.stringify({...values, 'user': user.email}, null, 2));

      const submitType = values.submitType
      let invoiceNumber = values.invoiceNumber
      let token = await getAccessTokenSilently();

      if (invoiceNumber === "") {
        console.log("Creating new invoice...")
        const response = await fetch("https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/invoices", {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({...values, 'user': user.email}) // body data type must match "Content-Type" header
        });
    
        const response_json = await response.json()
        console.log(response_json)
        invoiceNumber = await response_json['invoice_number']
        let contact = await response_json['contact']
        actions.resetForm({values: { ...values, 'invoiceNumber': invoiceNumber, 'contact': contact }})
        props.history.push('/invoices/' + invoiceNumber)
      } else {
        console.log("Updating invoice number: " + invoiceNumber)
        const response = await fetch("https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/invoices/"+invoiceNumber, {
          method: 'PUT', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({...values, 'user': user.email}) // body data type must match "Content-Type" header
        });
    
        const response_json = await response.json()
        console.log(response_json)
        if (!paramsId || paramsId != invoiceNumber) {
          props.history.push('/invoices/' + invoiceNumber)
        }
      }

      actions.setSubmitting(false)   
      
      // Check submit button type to decide on content calls
      // Could be nicer.. ref - https://github.com/formium/formik/issues/214 and https://github.com/formium/formik/pull/2437 
      if(submitType === "generateInvoice") {
        window.location.href="https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/invoices/"+invoiceNumber+"/download-pdf-invoice?token=" + token
      } else if (submitType === "generateCofC") {
        window.location.href="https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/invoices/"+invoiceNumber+"/download-pdf-cofc?token=" + token
      }
    }}
  >
    {({ setFieldValue, handleSubmit, values }) => (
      <Form autoComplete="off">
        <div className="row mt-1">
          <div className="col">
            <div className="row m-2 no-gutters">
              <MyField name="contact"/>
            </div>
            <div className="row m-2">
              <Field name="invoiceNumber">
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <TextField
                    fullWidth
                    id="invoiceNumber"
                    label="Invoice Number"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row m-2">
              <Field name="date">
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <TextField
                    fullWidth
                    id="date"
                    label="Date"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    InputProps={{
                      readOnly: true,
                    }}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row m-2">
              <Field name="orderNumber">
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <TextField
                    fullWidth
                    id="orderNumber"
                    label="Order Number"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className="col">
            <div className="row m-2 no-gutters">
              <div className="col">
                <Field name="billingAttn">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="billingAttn"
                      label="Billing: Attn"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col">
                <Field name="billingCompany">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="billingCompany"
                      label="Billing: Company"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="row m-2">
              <Field name="billingAddress1">
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <TextField
                    fullWidth
                    id="billingAddress1"
                    label="Billing: Address Line 1"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row m-2">
              <Field name="billingAddress2">
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <TextField
                    fullWidth
                    id="billingAddress2"
                    label="Billing: Address Line 2"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row m-2 no-gutters">
              <div className="col">
                <Field name="billingCity">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="billingCity"
                      label="Billing: City"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col">
                <Field name="billingState">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="billingState"
                      label="Billing: State"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col">
                <Field name="billingPostCode">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="billingPostCode"
                      label="Billing: Post Code"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row m-2 no-gutters">
              <div className="col">
                <Field name="deliveryAttn">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="deliveryAttn"
                      label="Delivery: Attn"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col">
                <Field name="deliveryCompany">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="deliveryCompany"
                      label="Delivery: Company"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="row m-2">
              <Field name="deliveryAddress1">
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <TextField
                    fullWidth
                    id="deliveryAddress1"
                    label="Delivery: Address Line 1"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row m-2">
              <Field name="deliveryAddress2">
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <TextField
                    fullWidth
                    id="deliveryAddress2"
                    label="Delivery: Address Line 2"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row m-2 no-gutters">
              <div className="col">
                <Field name="deliveryCity">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="deliveryCity"
                      label="Delivery: City"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col">
                <Field name="deliveryState">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="deliveryState"
                      label="Delivery: State"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col">
                <Field name="deliveryPostCode">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id="deliveryPostCode"
                      label="Delivery: Post Code"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-1 align-self-center"><h3>Items</h3></div>
        </div>
        <FieldArray name="items">
          {({ insert, remove, push }) => (
            <div>
              {values.items.length > 0 &&
                values.items.map((item, index) => (
                  <div className="row mt-1" key={index}>
                    <div className="col-auto align-self-center m-2">Item {index+1}</div>
                    <div className="col">
                      <Field name={`items.${index}.partNumber`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Part No."
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col">
                      <Field name={`items.${index}.partNumberAlt`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Part No. Alt."
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-2">
                      <Field name={`items.${index}.description`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Description"
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col">
                      <Field name={`items.${index}.traceability`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Traceability"
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1">
                      <Field name={`items.${index}.ordered`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Ordered"
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1">
                      <Field name={`items.${index}.shipped`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Shipped"
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1">
                      <Field name={`items.${index}.unitPrice`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Unit $ (ex.)"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1">
                      <Field name={`items.${index}.cofc`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div style={{marginTop: "6px"}}>
                            <FormControlLabel
                              control={
                                <Checkbox 
                                  id={field.name}
                                  checked={field.value}
                                  {...field}
                                  // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                                />
                              }
                              label="C of C"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1 align-self-center">
                      <Button color="secondary"
                        variant="contained" 
                        onClick={() => remove(index)}
                      >
                        X
                      </Button>
                    </div>
                  </div>
                ))}
              <div className="row mt-3 justify-content-end">
                <div className="col-1 align-self-right">
                  <Button color="secondary"
                    variant="contained" 
                    onClick={() => push({
                      partNumber: '',
                      description: '',
                      traceability: '',
                      ordered: '',
                      shipped: '',
                      unitPrice: '',
                      cofc: '',
                    },)}
                  >
                    Add Item
                  </Button>
                </div>
              </div>
            </div>
          )}
        </FieldArray>
        <div className="row mt-5 justify-content-end">
          <div className="col-2">
            <Field name="deliveryProvider">
              {({
                field, // { name, value, onChange, onBlur }
                form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
              }) => (
                <TextField
                  fullWidth
                  id="deliveryProvider"
                  label="Delivery Provider"
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="col-2">
            <Field name="deliveryTrackingNumber">
            {({
              field, // { name, value, onChange, onBlur }
              form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              meta,
            }) => (
              <TextField
                fullWidth
                id="deliveryTrackingNumber"
                label="Delivery Tracking Number"
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                {...field}
              />
            )}
          </Field>
          </div>
          <div className="col-1">
          </div>
          <div className="col-2">
            <Field name={`gstFree`}>
              {({
                field,
                form,
                meta,
              }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        id={field.name}
                        checked={field.value}
                        {...field}
                        // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                      />
                    }
                    label="GST Free?"
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="col-1">
            <Button color="primary" variant="contained" fullWidth type="button"
              onClick={() => {
                setFieldValue('submitType', 'saveInvoice', false)
                setTimeout(handleSubmit(),0)
              }}
            >
              Save
            </Button>
          </div>
          <div className="col-2">
            <Button color="primary" variant="contained" fullWidth type="button"
              onClick={() => {
                setFieldValue('submitType', 'generateInvoice', false)
                setTimeout(handleSubmit(),0)
              }}
            >
              Generate Invoice/Delivery
            </Button>
          </div>
          <div className="col-2">
            <Button color="primary" variant="contained" fullWidth type="button" disabled={!checkSupportDocsRequired(values.items)}
              onClick={() => {
                setFieldValue('submitType', 'generateCofC', false)
                setTimeout(handleSubmit(),0)
              }}
            >
              Generate Support Docs
            </Button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
  </div> ):
  (<span>loading...</span>);
};