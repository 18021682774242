import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

import { useAuth0 } from "@auth0/auth0-react";

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader'
import {getPresignedUploadParams} from '../modules/helpers/upload'

let defaultValues = {
  reference: '',
  customer: '',
  customerReference: '',
  date: '',
  comments: '',
  attachments: [],
  items: [
    {
      bidSuccessful: true,
      partNumber: '',
      altPartNumber: '',
      quantity: '',
      cost: '',
      costCurrency: 'AUD',
      sell: '',
      leadTime: '',
      invoicedOn: [
        {
          invoiceNumber: '',
          date: '',
          quantity: ''
        }
      ],
      purchasedFrom: {
        purchaseOrderNumber: '',
        name: '',
        contact: '',
        phone: '',
        email: ''
      }
    }
  ]
};

let costCurrencies = [
  {
    id: 'AUD',
    name: 'AUD'
  },
  {
    id: 'USD',
    name: 'USD'
  },
  {
    id: 'EURO',
    name: 'EURO'
  },
  {
    id: 'FRANC',
    name: 'FRANC'
  },
  {
    id: 'POUND',
    name: 'POUND'
  }
]

const validationSchema = Yup.object().shape({
  lastName: Yup.string('')
    .email('Enter a valid email')
    .required('Email is required'),
  // friends: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       name: Yup.string().required("Name is required"),
  //       email: Yup.string()
  //         .email("Invalid email")
  //         .required("Please enter email"),
  //     })
  //   )
  //   .min(1, "Need at least a friend")
});

export const OrderForm = (props) => {
  const [initialValues, setInitialValues] = useState();
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const paramsId = props.match.params.id
  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  async function getInitialValues(id) {
    let token = await getAccessTokenSilently();

    return fetch("https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/orders/" + id, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
       'Accept': 'application/json',
       'Authorization': "Bearer " + token
      }
    }).then(async (response) => {
      const data = await response.json()
      console.log(data)
      return data
    })
  }

  useEffect(() => {
    if (paramsId) {
      getInitialValues(paramsId).then((res) => {
        setInitialValues(res)
        setUploadDisabled(false)
      });
    } else {
      setInitialValues(defaultValues)
    }
  }, []);

  // specify upload params and url for your files
  const getUploadParams = async ({ meta: { name, size, lastModifiedDate } }) => {
    let token = await getAccessTokenSilently();
    const { id, fields, url } = await getPresignedUploadParams(name, size, lastModifiedDate, token)
    const fileUrl = url + '/' + fields.key
    return { fields, meta: { fileUrl, uploadId: id }, url: url }
  }

  // called every time a file's `status` changes
  async function handleFileChangeStatus(meta, file, status) { 
    console.log(status, meta, file)

    switch(status){
      case 'done':
        let token = await getAccessTokenSilently();
        const uploadId = meta.uploadId
        let response = await fetch('https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/orders/' + paramsId + '/attachments', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          body: JSON.stringify({...meta})
        })

        let response_json = await response.json()
        console.log(response_json)
        return response_json
        break;
      default:
        break;
    }
  }

  async function downloadFile(id) {
    let token = await getAccessTokenSilently();
    window.location.href = "https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/files/" + id + "/download?token=" + token
  }

  // receives array of files that are done uploading when submit button is clicked
  const handleFileSubmit = (files, allFiles) => {
    console.log(files.map(f => f.meta))
    allFiles.forEach(f => f.remove())
  }

  return initialValues ? (

  <div>
  <h1>Order Records</h1>
  <Formik
    initialValues={initialValues}
    //enableReinitialize={true}
    onSubmit={async (values, actions) => {
      //await new Promise((r) => setTimeout(r, 500));
      //alert(JSON.stringify({...values, 'user': user.email}, null, 2));
      
      const submitType = values.submitType
      let orderNumber = values.orderNumber
      let token = await getAccessTokenSilently();

      if (!!paramsId) {
        console.log("Updating order number: " + values.orderNumber + " (" + paramsId + ")")
        const response = await fetch("https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/orders/"+paramsId, {
          method: 'PUT', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({...values, 'user': user.email}) // body data type must match "Content-Type" header
        });
    
        const response_json = await response.json()
        console.log(response_json)
        setUploadDisabled(false)
      } else {
        console.log("Creating new order...")
        const response = await fetch("https://hu5gu0k8eb.execute-api.ap-southeast-2.amazonaws.com/dev/rest/orders", {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({...values, 'user': user.email}) // body data type must match "Content-Type" header
        });
    
        const response_json = await response.json()
        console.log(response_json)
        let orderId = await response_json['id']
        //actions.resetForm({values: { ...values}})
        props.history.push('/orders/' + orderId)
        setUploadDisabled(false)
      }

      actions.setSubmitting(false)   
    }}
  >
    {({ setFieldValue, handleSubmit, values }) => (
      <Form autoComplete="off">
        <div className="row mt-1">
          <div className="col">
            <div className="row m-2">
              <Field name="reference">
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <TextField
                    fullWidth
                    id={field.name}
                    label="Our Reference"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="row">
              <div className="col m-2">
                <Field name="customer">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id={field.name}
                      label="Customer Name"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="col m-2">
                <Field name="customerReference">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <TextField
                      fullWidth
                      id={field.name}
                      label="Customer Reference"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="row m-2">
              <Field name="comments">
                {({
                  field, // { name, value, onChange, onBlur }
                  form, // touched, errors, values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <TextField
                    fullWidth
                    rows="3"
                    rowsMax="10"
                    id={field.name}
                    label="Comments"
                    error={meta.touched && Boolean(meta.error)}
                    multiline
                    margin="dense"
                    variant="outlined"
                    {...field}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className="col">
            <div className="row ml-2">
              <Typography variant="h6">
                Attachments
              </Typography>
            </div>
            <div className="row m-5">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={async ({ meta, file }, status) => {
                  const attachment = await handleFileChangeStatus(meta, file, status)

                  if (status === 'done' && attachment) {
                    const attachments = values.attachments
                    console.log([...attachments, attachment])

                    setFieldValue('attachments', [...attachments, attachment])
                  }
                }}
                disabled={uploadDisabled}
                SubmitButtonComponent={null}
                //accept="image/*,audio/*,video/*"
              />
            </div>
          </div>
          <div className="col">
            <div className="row">
              <List>
                {values.attachments.length > 0 && values.attachments.map((attachment, attachmentIndex) => (
                  <ListItem key={attachmentIndex} button onClick={() => downloadFile(attachment.id)}>
                    <ListItemText
                      primary={attachment.name}
                      secondary={attachment.size + " bytes - " + attachment.date}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-1 align-self-center"><h3>Items</h3></div>
        </div>
        <FieldArray name="items">
          {({ insert, remove, push }) => (
            <div>
              {values.items.length > 0 && values.items.map((item, index) => (
                <div key={index}>
                  <div className="row mt-1">
                    <div className="col-auto align-self-center m-2">Item {index+1}</div>
                    <div className="col">
                      <Field name={`items.${index}.partNumber`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Part No."
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col">
                      <Field name={`items.${index}.altPartNumber`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Part No. Alt."
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1">
                      <Field name={`items.${index}.quantity`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Quantity"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1">
                      <Field name={`items.${index}.cost`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Cost (ex.)"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1">
                      <Field name={`items.${index}.costCurrency`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              margin="dense"
                              variant="outlined"
                              select
                              label="Cost"
                              style={{width:"100%"}}
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            >
                              {costCurrencies.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1">
                      <Field name={`items.${index}.sell`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Sell (ex.)"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1">
                      <Field name={`items.${index}.bidSuccessful`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div style={{marginTop: "6px"}}>
                            <FormControlLabel
                              control={
                                <Checkbox 
                                  id={field.name}
                                  checked={field.value}
                                  {...field}
                                  // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                                />
                              }
                              label="Won?"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-1 align-self-center">
                      <Button color="secondary"
                        variant="contained" 
                        onClick={() => remove(index)}
                      >
                        X
                      </Button>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col align-self-center m-2 ml-5">Purchased From</div>
                    <div className="col-2">
                      <Field name={`items.${index}.purchasedFrom.purchaseOrderNumber`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="P.O. Number"
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-2">
                      <Field name={`items.${index}.purchasedFrom.name`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Name"
                              multiline
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-2">
                      <Field name={`items.${index}.purchasedFrom.contact`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Contact"
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-2">
                      <Field name={`items.${index}.purchasedFrom.phone`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Phone"
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-2">
                      <Field name={`items.${index}.purchasedFrom.email`}>
                        {({
                          field,
                          form,
                          meta,
                        }) => (
                          <div>
                            <TextField
                              fullWidth
                              id={field.name}
                              label="Email"
                              margin="dense"
                              variant="outlined"
                              {...field}
                              // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  {//TODO - convert to FieldArray. Need to confirm use case.
                  }
                  {values.items[index].invoicedOn.length > 0 && values.items[index].invoicedOn.map((invoicedOnItem, invoicedOnIndex) => (
                    <div className="row mt-1" key={"invoicedOn-" + index}>
                      {invoicedOnIndex === 0 ? (<div className="col align-self-center m-2 ml-5">Invoiced On</div>) : (<div className="col-2 align-self-center m-2"></div>)}
                      <div className="col-2">
                        <Field name={`items.${index}.invoicedOn.${invoicedOnIndex}.invoiceNumber`}>
                          {({
                            field,
                            form,
                            meta,
                          }) => (
                            <div>
                              <TextField
                                fullWidth
                                id={field.name}
                                label="Invoice Number"
                                margin="dense"
                                variant="outlined"
                                {...field}
                                // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="col-1">
                        <Field name={`items.${index}.invoicedOn.${invoicedOnIndex}.quantity`}>
                          {({
                            field,
                            form,
                            meta,
                          }) => (
                            <div>
                              <TextField
                                fullWidth
                                id={field.name}
                                label="Quantity"
                                margin="dense"
                                variant="outlined"
                                {...field}
                                // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="col-2">
                        <Field name={`items.${index}.invoicedOn.${invoicedOnIndex}.date`}>
                          {({
                            field,
                            form,
                            meta,
                          }) => (
                            <div>
                              <TextField
                                fullWidth
                                id={field.name}
                                label="Date"
                                margin="dense"
                                variant="outlined"
                                {...field}
                                // To enable error and helperText we need to check the error and touched arrays as done at https://codesandbox.io/s/formik-fieldarray-with-yup-validation-xziy0?from-embed=&file=/src/index.js
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  ))}
                </div>
              ))}

              <div className="row mt-3 justify-content-end">
                <div className="col-1 align-self-right">
                  <Button color="secondary"
                    variant="contained" 
                    onClick={() => push({
                      bidSuccessful: true,
                      partNumber: '',
                      altPartNumber: '',
                      quantity: '',
                      cost: '',
                      costCurrency: '',
                      sell: '',
                      leadTime: '',
                      invoicedOn: [
                        {
                          invoiceNumber: '',
                          date: '',
                          quantity: ''
                        }
                      ],
                      purchasedFrom: {
                        purchaseOrderNumber: '',
                        name: '',
                        contact: '',
                        phone: '',
                        email: ''
                      }
                    },)}
                  >
                    Add Item
                  </Button>
                </div>
              </div>
            </div>
          )}
        </FieldArray>
        <div className="row mt-5 justify-content-end">
          <div className="col-1">
            <Button color="primary" variant="contained" fullWidth type="button"
              onClick={() => {
                setFieldValue('submitType', 'save', false)
                setTimeout(handleSubmit(),0)
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
  </div> ):
  (<span>loading...</span>);
};