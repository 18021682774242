/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/* Databases */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Databases</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/* Orders */}
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/orders", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/orders">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Orders</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/* Inventory */}
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/inventory", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/inventory">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Inventory</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/* Forms */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Forms</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/* Invoices */}
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/invoices", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/invoices">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Invoices</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/* Forms */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Search</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/* Search */}
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/search", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/search">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Search</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
